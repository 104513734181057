import React from "react";
import App from "./router";

import AppDrawer from "./framework/drawer";
import AppLoader from "./framework/loader";
import AppNavigation from "./framework/navigation";
import AppFooter from "./framework/footer";

export default function AppMain() {
  return (
    <React.Fragment>
      <AppLoader />
      <AppDrawer />
      <AppNavigation />
      <App />
      <AppFooter />
    </React.Fragment>
  );
}
