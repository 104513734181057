import React from "react";
import AppTextImage from "../../../../../components/app-text-image";

export default function TextArea() {
  return (
    <AppTextImage
    backgroundColor="#ffe5ca"
      title="Hoe wij ons huwelijk goed houden"
      reverse
      text={`Wij zijn Lucy (1954) & René (1950) Grifhorst, getrouwd in 1975. We hebben 4 kinderen gekregen en zijn opa en oma van negen kleinkinderen. We zijn heel tevreden met elkaar... Maar als christenen lopen we ook tegen onze tekortkomingen aan. Volmaakt in Christus, maar niet altijd volmaakt in ons huwelijk. Dat kan ook niet altijd, maar we “vechten” er voor om ons huwelijk, in Zijn kracht, zo goed mogelijk te maken en te houden. Nu, na al die jaren samen, zijn we ervan overtuigd dat ons goede huwelijk feitelijk gebaseerd is op het wederzijdse verlangen om God te dienen en daardoor elkaar. M.a.w. het is steeds de keuze voor Hem (en daardoor voor elkaar) die ons leidt, verblijdt en verdiept. We ervaren en worden bevestigd dat die keuze en houding aan mensen in onze omgeving rust geeft en vertrouwen.\n\n### Hoe wij jullie onzekerheid kunnen wegnemen\n\nNa jaren in het bedrijfsleven werkzaam te zijn geweest werden we in 1991 fulltime Discovery-werkers vanuit de internationale evangelisatie- en toerustingsbeweging Agapè in Doorn. In de loop der tijd veranderde ons werk van specifiek jongerenwerk naar een bredere doelgroep. Dat resulteerde o.a. in het Agapè-stadswerk in Zutphen. Echter, vooral de pastorale zorg speciaal voor echtparen - en jonge stellen, die huwelijksvoorbereiding van ons krijgen - bepaalde ons leven meer en meer. We hebben, om die pastorale zorg (ook didactisch) te onderbouwen, o.a. een 4-jarige opleiding bij Koinonia gevolgd: "de school voor pastorale werkers en christelijke hulpverleners". Sinds januari 2008 zijn wij fulltime, als huwelijkscounselors, verbonden aan stichting (V)écht ervoor!`}
      image={require("../../../../../assets/aan_tafel.jpg")}
    >
    </AppTextImage>
  );
}
