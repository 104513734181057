import React from "react";
import { Divider } from "@material-ui/core";
import { Helmet } from "react-helmet";
import AppTextArea from "./components/text-area";
import AppHeader from "./components/header";
import TopBanner from "../../framework/app-usp-bar";
import TopCallToAction from "../../framework/top-call-to-action";
import Reviews from "../../framework/getuigenissen";
import withRoot from "../../theme/withRoot";

function AppHome() {
  return (
    <React.Fragment>
      <Helmet>
        <title>Huwelijksinvestering | Stichting (V)écht ervoor!</title>
        <meta
          name="description"
          content="Stichting (V)écht ervoor! heeft als doel om christelijke echtparen - die in een bepaalde relationele nood zitten - geestelijke en praktische handvatten aan te reiken vanuit een christelijke invalshoek."
        />
        <meta
          property="og:description"
          content="Stichting (V)écht ervoor! heeft als doel om christelijke echtparen - die in een bepaalde relationele nood zitten - geestelijke en praktische handvatten aan te reiken vanuit een christelijke invalshoek."
        />
        <meta
          property="og:title"
          content="Huwelijksinvestering | Stichting (V)écht ervoor!"
        />
        <meta property="og:url" content={"https://vechtervoor.com"} />
      </Helmet>
      <AppHeader />
      <Divider />
      <TopCallToAction />
      <Divider />
      <TopBanner />
      <Divider />
      <AppTextArea />
      <Divider />
      <Reviews />
      <Divider />
    </React.Fragment>
  );
}

export default withRoot(AppHome);
