import React from "react";
import AppTextImage from "../../../../components/app-text-image";

export default function TextArea() {
  return (
    <AppTextImage
      reverse
      title="Over ons"
      image={require("../../../../assets/voordeur.jpg")}
      text={`Wij zijn Lucy (1954) & René (1950) Grifhorst, getrouwd in 1975. We hebben 4 kinderen gekregen en zijn opa en oma van negen kleinkinderen. We zijn heel tevreden met elkaar... Maar als christenen lopen we ook tegen onze tekortkomingen aan. Volmaakt in Christus, maar niet altijd volmaakt in ons huwelijk. Dat kan ook niet altijd, maar we “vechten” er voor om ons huwelijk, in Zijn kracht, zo goed mogelijk te maken en te houden. Nu, na al die jaren samen, zijn we ervan overtuigd dat ons goede huwelijk feitelijk gebaseerd is op het wederzijdse verlangen om God te dienen en daardoor elkaar. M.a.w. het is steeds de keuze voor Hem (en daardoor voor elkaar) die ons leidt, verblijdt en verdiept. We ervaren en worden bevestigd dat die keuze en houding aan mensen in onze omgeving rust geeft en vertrouwen.
      `}
    />
  );
}
