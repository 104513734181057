import React from "react";
import { Divider } from "@material-ui/core";
import { Helmet } from "react-helmet";

import AppTextArea from "./components/text-area";
import AppTextKosten from "./components/text-area-kosten";
import AppHeader from "./components/header";
import AppFormBottom from "./components/form-bottom";
import TopCallToAction from "../../../framework/top-call-to-action";
import withRoot from "../../../theme/withRoot";

function AppHome() {
  return (
    <React.Fragment>
      <Helmet>
        <title>Huwelijkspastoraat voor Christelijke echtparen | Stichting (V)écht ervoor!</title>
        <meta
          name="description"
          content="Stichting (V)écht ervoor! heeft als doel om christelijke echtparen - die in een bepaalde relationele nood zitten - huwelijkspastoraat aan te reiken vanuit een christelijke invalshoek."
        />
        <meta
          property="og:description"
          content="Stichting (V)écht ervoor! heeft als doel om christelijke echtparen - die in een bepaalde relationele nood zitten - huwelijkspastoraat aan te reiken vanuit een christelijke invalshoek."
        />
        <meta
          property="og:title"
          content="Huwelijkspastoraat voor Christelijke echtparen | Stichting (V)écht ervoor!"
        />
        <meta property="og:url" content={"https://vechtervoor.com/huwelijks-pastoraat"} />
      </Helmet>
      <Divider />
      <AppHeader />
      <Divider />

      {/* <TopBanner title="Onze pastorale aanpak" backgroundColor="#FFF1E3" />
      <Divider /> */}
      <AppTextArea />
      <Divider />
      <TopCallToAction title="Plan een pastoraal gesprek" backgroundColor="#ffe5ca" />
      <Divider />
      <AppTextKosten />
      {/* <Divider />
      <Reviews backgroundColor="#FFF1E3" /> */}
      <Divider />
      <AppFormBottom />
    </React.Fragment>
  );
}

export default withRoot(AppHome);
