import React from "react";
import { Grid, createStyles, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    colorWhite: {
      color: "#fff",
    },
    container: {
      minHeight: "100vh",
      margin: "-65px 0 0",
      width: "100%",
      backgroundImage: `url(${require(`../../../../assets/samen_close_up.jpg`)})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundAttachment: "fixed",
      backgroundPosition: "center",
      [theme.breakpoints.down("sm")]: {
        minHeight: "50vh",
        backgroundAttachment: "inherit",
      },
    },
    content: {
      alignItems: "center",
      display: "flex",
      textAlign: "left",
      flexDirection: "column",
      justifyContent: "center",
      maxWidth: "550px",
      marginLeft: "100px",
      [theme.breakpoints.down("sm")]: {
        marginLeft: "0px",
        maxWidth: "100%",
        textAlign: "center",
      },
    },
    title: {
      color: "#FFF",
      fontFamily: "'Raleway', sans-serif;",
      fontWeight: 700,
      textTransform: "uppercase",
      fontSize: "4rem",
      [theme.breakpoints.up("lg")]: {
        fontSize: "6rem",
      },
    },
    subTitle: {
      color: "#FFF",
      fontFamily: "'Raleway', sans-serif;",
      fontSize: "1rem",
      fontSize: "22px",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  })
);

export default function Header() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid container spacing={10} className={classes.container}>
        <Grid item xs={12} sm={12} md={6} className={classes.content}>
          <Typography
            variant="h1"
            component="h1"
            gutterBottom
            className={classes.title}
          >
            Stichting (V)écht ervoor!
          </Typography>
          <Typography
            variant="subtitle1"
            component="p"
            gutterBottom
            className={classes.subTitle}
          >
            Ieder huwelijk is het waard om voor te vechten.{" "}
            <b>Stichting (V)écht ervoor!</b> heeft als doel om echtparen - die
            in een bepaalde relationele nood zitten - geestelijke en praktische
            handvatten aan te reiken. Dat doen we vanuit een christelijke
            invalshoek: de uitgangspunten van de Here God en Zijn woord.
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
