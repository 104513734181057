import React from "react";
import Carousel from "react-material-ui-carousel";
import Review from "../app-review-slider";

export default function CustomizedButtons({ items }) {
  return (
    <Carousel>
      {items.map((item, i) => (
        <Review key={i} item={item} />
      ))}
    </Carousel>
  );
}
