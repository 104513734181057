import React, { useState, useEffect, useContext } from "react";
import {
  AppBar,
  Toolbar,
  makeStyles,
  createStyles,
  IconButton,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import classNames from "classnames";
import { Context } from "../../store/store";

const useStyles = makeStyles((theme) =>
  createStyles({
    colorDark: {
      color: "#000!important",
    },
    heartBeatIcon: {
      color: "#FFF",
      fontSize: "2rem",
    },
    heartBeatIconAwake: {
      color: "#000",
    },
    menuButton: {
      color: "#FFF",
      marginRight: "30px",
    },
    navigationBar: {
      position: "fixed",
      top: "-74px",
      transition: "transform 0.2s",
      transform: "translateY(74px)",
      background: "#FFF1E3",
    },
    navigationBarHide: {
      transform: "translateY(0)",
    },
    navigationBarInitial: {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
    navigationBarInner: {
      justifyContent: "space-between",
    },
    offset: {
      height: "74px",
    },
    offset0: {
      height: 0,
    },
    root: {
      flexGrow: 1,
      maxHeight: "64px",
    },
    title: {
      fontFamily: '"Raleway", sans-serif',
      fontWeight: 500,
      color: "#FFF",
    },
    titleAwake: {
      color: "#000",
    },
    logo: {
      display: "flex",
      width: "120px",
      height: "80px",
      justifySelf: "center",
      alignSelf: "center",
      backgroundSize: "cover",
      backgroundImage: `url(${require("../../assets/logo.png")})`,
      [theme.breakpoints.down("sm")]: {
        width: "80px",
        height: "50px",
      },
    },
    logoRevert: {
      display: "flex",
      width: "80px",
      height: "50px",
      justifySelf: "center",
      alignSelf: "center",
      backgroundSize: "cover",
      backgroundImage: `url(${require("../../assets/logo.png")})`,
    },
  })
);

export default function AppNavigation() {
  const classes = useStyles();

  const [, dispatch] = useContext(Context);

  const [atTopOfPage, setAtTopOfPage] = useState(true);
  const [sleep, setSleep] = useState(false);

  const handleScroll = () => {
    setAtTopOfPage(window.pageYOffset < 275 ? true : false);
    setSleep(window.pageYOffset < 350 ? true : false);
  };

  // show or hide the drawer
  const toggleDrawer = () => {
    dispatch({
      type: "TOGGLE_DRAWER",
    });
  };

  // component did unmount
  useEffect(() => {
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // component did mount
  useEffect(() => window.addEventListener("scroll", handleScroll), []);

  return (
    <div className={classes.root}>
      <AppBar
        color="default"
        position="relative"
        className={
          atTopOfPage
            ? classes.navigationBarInitial
            : sleep
            ? classNames(classes.navigationBar, classes.navigationBarHide)
            : classes.navigationBar
        }
      >
        <Toolbar className={classes.navigationBarInner}>
          <IconButton
            edge="start"
            className={
              atTopOfPage
                ? classes.menuButton
                : classNames(classes.menuButton, classes.colorDark)
            }
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer}
          >
            <MenuIcon />
          </IconButton>
          <span
            className={
              atTopOfPage
                ? classes.title
                : classNames(classes.title, classes.titleAwake)
            }
          >
            {document.location.hostname}
          </span>
          <a
            href="/"
            className={
              atTopOfPage
                ? classes.heartBeatIcon
                : classNames(classes.heartBeatIcon, classes.heartBeatIconAwake)
            }
          >
            <span
              className={
                atTopOfPage
                  ? classes.logo
                  : classNames(classes.logo, classes.logoRevert)
              }
            ></span>
          </a>
        </Toolbar>
      </AppBar>
      <div className={atTopOfPage ? classes.offset0 : classes.offset}></div>
    </div>
  );
}
