import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Card,
  CardMedia,
  CardActions,
  CardContent,
  Typography,
} from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    boxShadow: "0 0 2.5rem 0.3125rem rgba(0,0,0,.07)",
    display: "flex",
    flexDirection: "column",
    background: "#E8A279",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
  textDecoration: {
    textDecoration: "none!important",
    height: "100%",
  },
  buttonColor: {
    color: "#f2713a",
  },
  fontFamily: {
    fontFamily: "'Raleway', sans-serif;",
    color: "#FFF",
  },
  content: {
    fontSize: "1.1rem",
    textAlign: "justify",
    fontWeight: 300,
    lineHeight: 1.8,
  },
}));

export default function Appreview({ review }) {
  const classes = useStyles();

  if (!review) {
    return null;
  }

  return (
    <Card className={classes.root}>
      <CardMedia
        component="img"
        alt={review.image.url}
        height="140"
        src={require("../../assets/sunset.jpg")}
        title={review.title}
      />
      <CardContent>
        <Typography
          gutterBottom
          variant="h5"
          component="h2"
          className={classes.fontFamily}
        >
          {review.title}
        </Typography>
      </CardContent>
      <CardContent>
        <Typography
          gutterBottom
          variant="p"
          component="p"
          className={classNames(classes.fontFamily, classes.content)}
        >
          {review.subtitle}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <Button
          href={`/getuigenissen/${review.slug}`}
          className={classNames(classes.buttonColor, classes.fontFamily)}
        >
          Lees getuigenis
        </Button>
      </CardActions>
    </Card>
  );
}
