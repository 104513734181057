import React from "react";
import AppTextImage from "../../../../components/app-text-image";

export default function TextArea() {
  return (
    <AppTextImage
      reverse
      title="Kosten naar draagkracht"
      image={require("../../../../assets/aan_tafel.jpg")}
      text={`We rekenen geen counselingkosten, daarom sturen we als stichting ook geen facturen. Wel verzoeken we jullie een gift - naar draagkracht - over te maken. Deze giften zijn mogelijk aftrekbaar van de belastingen, mede omdat we door de fiscus als een Algemeen Nut Beogende Instelling worden aangemerkt. [Bekijk hier onze ANBI-gegevens.](/anbi-status)\n\n* De giften voor de stichting worden aangewend voor werk- en kantoorkosten.\n\n * Mocht het geven van een gift een (tijdelijk) probleem vormen laat je dan niet weerhouden toch contact met ons op te nemen, het gaat ons niet om de gift, maar om jullie huwelijk!\n\nRekeningnummer Stichting (V)écht ervoor!: **NL58 INGB 0004 8761 36**.\n\n### Persoonlijke aandacht\n\nOnze counseling kenmerkt zich door persoonlijke aandacht, in een entourage van o.a. gezelligheid, humor, rust, transparantie en vertrouwelijkheid. Met een luisterend oor en veel empathie willen wij er voor jullie zijn...\n\nNB.: hebben jullie interesse? We nodigen jullie van harte uit voor een nadere [kennismaking](/contact)!.
      `}
    />
  );
}
