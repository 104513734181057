import React from "react";
import { createStyles, makeStyles, Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    colorWhite: {
      color: "#fff",
    },
    container: {
      minHeight: "450px",
      margin: "-65px 0 0",
      width: "100%",
    },
    content: {
      alignItems: "center",
      backgroundColor: "rgba(0,0,0, 0.4)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      padding: "40px",
    },
    title: {
      color: "white",
      fontFamily: '"Raleway", sans-serif',
      textTransform: "uppercase",
      [theme.breakpoints.down("xs")]: {
        fontSize: "2.2rem",
      },
    },
    subTitle: {
      color: "#4d4d4d",
      fontFamily: '"Raleway", sans-serif',
      fontSize: "1rem",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  })
);

export default function AppHeroHeader({ subTitle, title, background }) {
  const classes = useStyles();

  const injectBackGroundImage = (image) => ({
    backgroundImage: `url(${image})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  });

  return (
    <React.Fragment>
      <Grid
        container
        spacing={10}
        className={classes.container}
        justify="space-around"
        style={injectBackGroundImage(
          require(`../../assets/${background.toLowerCase()}`)
        )}
      >
        <Grid item xs={12} className={classes.content}>
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="textPrimary"
            className={classes.title}
            gutterBottom
          >
            {title}
          </Typography>
          {subTitle && (
            <Typography
              variant="h5"
              align="center"
              color="textSecondary"
              className={classes.subTitle}
              style={{
                color: "rgba(255, 255, 255, 0.5)",
              }}
              paragraph
            >
              {subTitle}
            </Typography>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
