import React from "react";
import {
  makeStyles,
  Typography,
  Avatar,
  Card,
  CardContent,
  CardMedia,
  Grid,
} from "@material-ui/core";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    background: "#E8A279",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      textAlign: "center",
    },
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
    },
  },
  cover: {
    minWidth: 250,
    [theme.breakpoints.down("sm")]: {
      minHeight: 250,
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    backgroundColor: theme.palette.primary.main,
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    backgroundColor: theme.palette.primary.main,
  },
  title: {
    fontFamily: "'Raleway', sans-serif;",
    fontWeight: 700,
    textTransform: "uppercase",
    margin: "15px 0",
    fontSize: "1.6rem",
  },
  titleBar: {
    display: "flex",
  },
  colorWhite: {
    color: "#FFF1E3",
  },
}));

export default function AppCard({ desc, title, children, background }) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Grid container className={classes.container} justify="space-around">
            <Grid
              item
              xs={12}
              sm={12}
              md={2}
              justify="center"
              alignContent="center"
              className={classes.titleBar}
            >
              <Avatar alt={title} className={classes.large}>
                {children}
              </Avatar>
            </Grid>
            <Grid item xs={12} sm={12} md={10}>
              <Typography
                className={classNames(classes.title, classes.colorWhite)}
                variant="h4"
                component="h4"
              >
                {title}
              </Typography>
              <Typography
                variant="subtitle1"
                component="p"
                className={classNames(classes.colorWhite)}
              >
                {desc}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </div>
      <CardMedia
        className={classes.cover}
        image={require(`../../assets/${background.toLowerCase()}`)}
      />
    </Card>
  );
}
