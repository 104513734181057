export default (state, action) => {
  switch (action.type) {
    case "TOGGLE_DRAWER":
      return {
        ...state,
        drawer: !state.drawer,
      };
    case "TOGGLE_LOADER":
      return {
        ...state,
        loader: action.status || !state.loader,
      };
    default:
      return state;
  }
};
