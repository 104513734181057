import React from "react";
import AppTextImage from "../../../../components/app-text-image";

export default function TextArea() {
  return (
    <AppTextImage
      reverse
      title="Waarom die naam? (V)écht ervoor!"
      image={require("../../../../assets/lucy_grifthorst.jpg")}
      text={`1. de grote V staat voor: Vader, zonder Hem willen we niets doen!\n\n2. vécht ervoor!: je wilt ervoor vechten, je wilt er alles aan doen!\n\n3. écht ervoor!:	je wilt er écht voor gaan - niet een beetje!\n\n4. echt ervoor: in de echt verbonden door God, dat telt, dat gaat voor !\n\n### Doel van Stichting (V)écht ervoor!\n\nStichting (V)écht ervoor! heeft als doel om echtparen - die in een bepaalde relationele nood zitten - geestelijke en praktische handvatten aan te reiken. Dat doen we vanuit een christelijke invalshoek: de uitgangspunten van de Here God en Zijn woord.\n\n### Hoe bereiken we dat doel?\n\nDoor middel van kort- of langer lopende counseling in de vorm van pastorale gesprekken. Deze vinden bij ons thuis plaats, in Zutphen.\n\n### Onze visie\n\nAls (christelijke) huwelijken gezonder, dus sterker worden, dan worden hun gezinnen (en kerkelijke gemeenten) dat ook! In Nederland loopt ruim 1 op de 3 huwelijken op de klippen - helaas ook christelijke huwelijken! Dat vinden wij onacceptabel; wij kunnen niet werkeloos toezien dat dergelijke huwelijken blijvend kapot gaan. Wij stellen daarom tijd en expertise beschikbaar om (christelijke) echtparen in geestelijke nood een pastorale hand toe te steken.`}
    />
  );
}
