import React from "react";
import {
  createStyles,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import { Grid, Link, Typography } from "@material-ui/core";
import classNames from "classnames";

const useStyles = makeStyles((theme) =>
  createStyles({
    anchor: {
      display: "flex",
      flexDirection: "column",
      fontSize: "1rem",
      lineHeight: 2,
      fontFamily: '"Raleway", sans-serif',
      fontWeight: 300,
    },
    container: {
      background: "#FFF1E3",
      margin: "0",
      width: "100%",
      padding: "40px",
      [theme.breakpoints.down("sm")]: {
        padding: "40px 0",
      },
    },
    content: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      padding: "40px",
    },
    image: {
      margin: "35px 0",
      width: "100%",
    },
    margin: {
      margin: theme.spacing(1),
    },
    title: {
      fontSize: "1rem !important",
      fontFamily: '"Raleway", sans-serif',
      fontWeight: 500,
      marginBottom: "1.5rem",
    },
    text: {
      fontSize: "1rem",
      lineHeight: 1.2,
      fontFamily: '"Raleway", sans-serif',
      fontWeight: 300,
      textAlign: "justify",
    },
    backgroundMain: {
      padding: "0",
      textAlign: "center",
      background: "#E8A279",
    },
    colorLight: {
      color: "#FFF1E3",
      fontWeight: 700,
    },
  })
);

export default function Footer() {
  const classes = useStyles();

  return (
    <ThemeProvider>
      <Grid container className={classes.container} justify="space-around">
        <Grid item xs={12} sm={12} md={6} lg={4} className={classes.content}>
          <Typography variant="h2" gutterBottom className={classes.title}>
            Stichting (V)écht ervoor!
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.text}>
            Stichting (V)écht ervoor! heeft als doel om echtparen - die in een
            bepaalde relationele nood zitten - geestelijke en praktische
            handvatten aan te reiken. Dat doen we vanuit een christelijke
            invalshoek: de uitgangspunten van de Here God en Zijn woord.
          </Typography>
          <Link
            href="https://www.geef.nl/nl/doel/stichting-v-echt-ervoor/anbi"
            target="_blank"
          >
            <iframe
              className={classes.image}
              width="100%"
              height="350"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              src="https://www.geef.nl/app/charities/1971/anbi-status?width=400"
            ></iframe>
          </Link>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} className={classes.content}>
          <Typography variant="h2" gutterBottom className={classes.title}>
            Contact
          </Typography>
          <Typography variant="body1" className={classes.anchor}>
            Lucy &amp; René Grifhorst <br />
            De Varentuin 11 7207 ML Zutphen
            <Link href="tel:06 27160090" target="_blank" color="inherit">
              06 27160090
            </Link>
            <Link
              href="mailto:info@vechtervoor.nl"
              target="_blank"
              color="inherit"
            >
              info@vechtervoor.nl
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4} className={classes.content}>
          <Typography variant="h2" gutterBottom className={classes.title}>
            Locatie
          </Typography>
          <iframe
            width="100%"
            height="350"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
            src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=nl&amp;q=+(stichting%20(V)%C3%A9cht%20ervoor!)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          ></iframe>
        </Grid>
      </Grid>
      <Grid
        container
        className={classNames([classes.container, classes.backgroundMain])}
        justify="space-around"
      >
        <Grid item xs={12} className={classes.content}>
          <Typography variant="body1" className={classes.anchor}>
            <Link
              href="//datails.nl"
              target="_blank"
              color="inherit"
              className={classes.colorLight}
            >
              Powered By DATAILS.
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
