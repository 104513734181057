import React from "react";
import {
  Typography,
  Paper,
  makeStyles,
  createStyles,
  Button,
} from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignContent: "center",
      alignItems: "center",
      padding: "40px",
      background: "#E8A279",
    },
    content: {
      fontFamily: "'Raleway', sans-serif;",
      margin: "50px 0",
      color: "#FFF1E3",
    },
    title: {
      fontFamily: "'Raleway', sans-serif;",
      color: "#FFF1E3",
    },
    button: {
      color: "#FFF1E3",
    },
    rating: {
      marginBottom: "25px",
    },
  })
);

export default function Review({ item }) {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Rating name="read-only" value={5} readOnly className={classes.rating} />
      <Typography variant="h6" component="h2" className={classes.title}>
        {item.title}
      </Typography>
      <Typography
        variant="subtitle1"
        component="subtitle1"
        className={classes.content}
      >
        {item.subtitle}
      </Typography>
      <Button href={`/getuigenissen/${item.slug}`} className={classes.button}>
        Lees getuigenis
      </Button>
    </Paper>
  );
}
