import React from "react";
import {
  Grid,
  createStyles,
  makeStyles,
  Paper,
  Typography,
  Avatar,
} from "@material-ui/core";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import AppButton from "../../components/app-button";
import classNames from "classnames";

const useStyles = makeStyles((theme) =>
  createStyles({
    paperContainer: {
      background: "#E8A279",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignContent: "center",
      alignItems: "center",
      padding: "40px 0",
      fontFamily: "'Raleway', sans-serif",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        textAlign: "center",
      },
    },
    container: {
      margin: "0",
      width: "100%",
      background: "#FFF1E3",
      padding: "120px 0",
      [theme.breakpoints.down("sm")]: {
        padding: 0,
      },
    },
    content: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      padding: "0 !important",
    },
    icon: {
      width: "5rem",
      height: "5rem",
      color: "#ffca91",
    },
    avatar: {
      width: "8rem",
      height: "8rem",
      backgroundColor: "#f3f5f9",
    },
    flex: {
      display: "flex",
    },
    contentText: {
      color: "#FFF1E3",
      [theme.breakpoints.down("sm")]: {
        padding: "15px 0 0",
      },
    },
    fontWeight700: {
      fontWeight: 700,
    },
  })
);

export default function ComponentTopBanner({ backgroundColor, title }) {
  const classes = useStyles();

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      className={classes.container}
      style={{ backgroundColor: backgroundColor }}
    >
      <Grid
        item
        xs={12}
        sm={12}
        md={10}
        xl={8}
        justify="center"
        alignItems="center"
      >
        <Paper className={classes.paperContainer}>
          <Grid
            item
            xs={12}
            sm={3}
            md={3}
            justify="center"
            alignItems="center"
            className={classes.flex}
          >
            <Avatar
              className={classes.avatar}
              alt="plan vrijblijvend een afspraak met stichting vechtervoor"
            >
              <EventAvailableIcon className={classes.icon} />
            </Avatar>
          </Grid>
          <Grid
            item
            xs={12}
            sm={9}
            md={6}
            justify="center"
            alignItems="start"
            className={classes.flex}
            direction="column"
          >
            <Typography
              component="h2"
              variant="h6"
              className={classNames(classes.contentText, classes.fontWeight700)}
            >
              {title || 'Plan een ontmoeting'}
            </Typography>
            <Typography
              component="h3"
              variant="subtitle1"
              className={classes.contentText}
            >
              Als jullie je huwelijksnood aan ons durven toevertrouwen nodigen
              we jullie van harte uit voor een zgn. intake(plus) - op afspraak -
              bij Stichting (V)écht ervoor! in Zutphen. Je kunt ons daarvoor
              mailen of bellen!
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            justify="center"
            alignItems="center"
            className={classes.flex}
          >
            <AppButton href="/contact">Meer info</AppButton>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
