import React from "react";
import AppHeader from "../../../../components/app-hero-header";

export default function TextArea() {
  return (
    <AppHeader
      subTitle="Als jullie je huwelijksnood aan ons durven toevertrouwen nodigen we jullie van harte uit voor een zgn. intake(plus) - op afspraak - bij ons thuis in Zutphen."
      title="Intake(plus) en vervolgcounseling"
      background="op_bank.jpg"
    />
  );
}
