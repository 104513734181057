import React from "react";
import { useRoutes, useInterceptor, useRedirect } from "hookrouter";
import AppHome from "../pages/home";
import AppAbout from "../pages/about";
import AppContact from "../pages/contact";
import AppReviews from "../pages/getuigenissen";
import AppReview from "../pages/review";
import AppCounseling from "../pages/counseling";
import AppAnbi from "../pages/anbi-status";
import Page404 from "../pages/404";
import AppPrivacy from "../pages/privacy";

import AppHuwelijksPastoraat from "../pages/landing-pages/huwelijks-pastoraat";
import AppOnzekerHuwelijk from "../pages/landing-pages/onzeker-huwelijk";
import AppRelatieTherapie from "../pages/landing-pages/relatie-therapie";

const routes = {
  "/": () => <AppHome />,
  "/anbi-status": () => <AppAnbi />,
  "/counseling": () => <AppCounseling />,
  "/getuigenissen": () => <AppReviews />,
  "/getuigenissen/:slug": ({ slug }) => <AppReview slug={slug} />,
  "/over-ons": () => <AppAbout />,
  "/contact": () => <AppContact />,
  "/privacyverklaring": () => <AppPrivacy />,

  "/huwelijks-pastoraat": () => <AppHuwelijksPastoraat />,
  "/onzeker-in-huwelijk": () => <AppOnzekerHuwelijk />,
  "/relatie-therapie": () => <AppRelatieTherapie />,
};

const App = () => {
  useRedirect('/wiezijnwij.html', '/over-ons');
  useRedirect('/contact.html', '/contact');
  useRedirect('/destichting.html', '/over-ons');
  useRedirect('/counseling.html', '/counseling');
  useRedirect('/anbi.html', '/anbi-status');

  useInterceptor((currentPath, nextPath) => {
    window.scrollTo(0, 0);
    window.dataLayer.push({
      event: "pageview",
    });
    return nextPath;
  });

  const routeResult = useRoutes(routes);
  return routeResult || <Page404 />;
};

export default App;
