import React from "react";
import AppHeader from "../../../../components/app-hero-header";

export default function () {
  return (
    <AppHeader
      // subTitle="Getuigenissen van onze counceling"
      title="Getuigenissen"
      background="op_stoeltjes_voor_kachel.jpg"
    />
  );
}
