import React from "react";
import { Grid, createStyles, makeStyles } from "@material-ui/core";
import Review from "../app-review";

const useStyles = makeStyles(() =>
  createStyles({
    content: {
      display: "flex",
      justifyContent: "center",
    },
  })
);

const Reviews = ({ reviews }) => {
  const classes = useStyles();
  const leftArticlesCount = Math.ceil(reviews.length / 5);
  const leftArticles = reviews.slice(0, leftArticlesCount);
  const rightArticles = reviews.slice(leftArticlesCount, reviews.length);

  return (
    <React.Fragment>
      {leftArticles.map((review, i) => {
        return (
          <Grid item sm={12} md={6} lg={4} xl={3} className={classes.content}>
            <Review review={review} key={`article__${review.id}`} />
          </Grid>
        );
      })}

      {rightArticles.map((review, i) => {
        return (
          <Grid item sm={12} md={6} lg={4} xl={3} className={classes.content}>
            <Review review={review} key={`article__${review.id}`} />
          </Grid>
        );
      })}
    </React.Fragment>
  );
};

export default Reviews;
