import React from "react";
import AppTextImage from "../../../../../components/app-text-image";
import AppForm from "../../../../../framework/form";

export default function TextArea() {
  return (
    <AppTextImage
      backgroundColor="#ffe5ca"
      title="Start met de therapie!"
      text={`Als jullie je huwelijksnood aan ons durven toevertrouwen nodigen we jullie van harte uit voor een pastoraal gesprek.\n\n* Lucy & René Grifhorst\n\n* De Varentuin 11\n\n* 7207 ML Zutphen\n\n* Mobielnr: [06 27160090](tel:0627160090)\n\n* E-mail: [info@vechtervoor.nl](mailto:info@vechtervoor.nl).\n\nNeem contact met ons op door te bellen/mailen, of door onderstaand formulier in te vullen.
      `}
    >
      <AppForm />
    </AppTextImage>
  );
}