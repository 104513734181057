import React from "react";
import { Divider } from "@material-ui/core";
import { Helmet } from "react-helmet";

import AppTextArea from "./components/text-area";
import AppTextKosten from "./components/text-area-kosten";
import AppHeader from "./components/header";
import AppFormBottom from "./components/form-bottom";
import TopCallToAction from "../../../framework/top-call-to-action";
import withRoot from "../../../theme/withRoot";

function AppHome() {
  return (
    <React.Fragment>
      <Helmet>
        <title>Onzekerheid in je huwelijk of relatie? | Stichting (V)écht ervoor!</title>
        <meta
          name="description"
          content="Stichting (V)écht ervoor! heeft als doel om christelijke echtparen - die onzeker zijn over hun huwelijk of relatie huwelijkspastoraat aan te reiken vanuit een christelijke invalshoek."
        />
        <meta
          property="og:description"
          content="Stichting (V)écht ervoor! heeft als doel om christelijke echtparen - die onzeker zijn over hun huwelijk of relatie huwelijkspastoraat aan te reiken vanuit een christelijke invalshoek."
        />
        <meta
          property="og:title"
          content="Onzekerheid in je huwelijk of relatie? | Stichting (V)écht ervoor!"
        />
        <meta property="og:url" content={"https://vechtervoor.com/onzeker-in-huwelijk"} />
      </Helmet>
      <Divider />
      <AppHeader />
      <Divider />
      <AppTextArea />
      <Divider />
      <TopCallToAction title="Onzeker in je relatie? Neem contact op!" backgroundColor="#ffe5ca" />
      <AppTextKosten />
      <Divider />
      <AppFormBottom />
    </React.Fragment>
  );
}

export default withRoot(AppHome);
