import React from "react";
import AppTextImage from "../../../../components/app-text-image";

export default function TextArea() {
  return (
    <AppTextImage
      backgroundColor="#ffe5ca"
      title="Pagina niet gevonden!"
      text={["Sorry deze pagina hebben wij niet kunnen vinden."]}
    />
  );
}
