import React from "react";
import { Helmet } from "react-helmet";
import { Divider } from "@material-ui/core";
import TopCallToAction from "../../framework/top-call-to-action";
import BreadCrumbs from "../../framework/breadcrumb";
import AppHeader from "./components/header";
import AppQuery from "./components/query";

export default function AppReview() {
  return (
    <React.Fragment>
      <Helmet>
        <title>Reviews en getuigenissen over Stichting (V)écht ervoor!</title>
        <meta
          name="description"
          content="Getuigenissen over het werk dat Stichting Vecht Ervoor levert en over de kracht van Gods beloften, in deze huwelijken."
        />
        <meta
          property="og:description"
          content="Getuigenissen over het werk dat Stichting Vecht Ervoor levert en over de kracht van Gods beloften, in deze huwelijken."
        />
        <meta
          property="og:title"
          content="Huwelijksinvestering | Stichting (V)écht ervoor! | Reviews en getuigenissen"
        />
        <meta
          property="og:url"
          content={"https://vechtervoor.com/getuigenissen"}
        />
      </Helmet>
      <Divider />
      <AppHeader />
      <Divider />
      <BreadCrumbs backgroundColor="#FFF1E3" />
      <Divider />
      <AppQuery />
      <Divider />
      <TopCallToAction backgroundColor="#ffe5ca" />
      <Divider />
    </React.Fragment>
  );
}
