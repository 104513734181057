import React from "react";
import { Grid, createStyles, makeStyles, Typography } from "@material-ui/core";
import AppUSPCard from "../../components/app-usp-bar";
import AppButton from "../../components/app-button";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      margin: "0",
      width: "100%",
      padding: "80px 40px",
      background: "#ffe5ca",
      [theme.breakpoints.down("sm")]: {
        padding: "40px 0",
      },
    },
    content: {
      display: "flex",
      justifyContent: "center",
    },
    title: {
      marginBottom: "50px",
    },
  })
);

export default function ComponentTopBanner({ title, backgroundColor }) {
  const classes = useStyles();

  return (
    <Grid
      container
      className={classes.container}
      justify="space-evenly"
      spacing={10}
      style={{backgroundColor: backgroundColor || '#ffe5ca'}}
    >
      <Grid item lg={12} xl={8} justify="center" align="center">
        <Grid container spacing={10} justify="space-evenly">
          <Grid item sm={12} justify="center" align="center">
            <Typography
              variant="h2"
              component="h2"
              gutterBottom
              className={classes.title}
            >
              {title || 'Hoe Stichting (V)écht ervoor! werkt' }
            </Typography>
            <Typography variant="subtitle1" component="h3">
              Onze counseling kenmerkt zich door persoonlijke aandacht, in een
              entourage van o.a. gezelligheid, humor, rust, transparantie en
              vertrouwelijkheid. <br />
              Met een luisterend oor en veel empathie willen wij er voor jullie
              zijn... NB.: hebben jullie interesse? <br />
              We nodigen jullie van harte uit voor een nadere kennismaking!
            </Typography>
          </Grid>
          <Grid item md={12} lg={6} className={classes.content}>
            <AppUSPCard
              background="aan_tafel.jpg"
              desc="Als jullie je huwelijksnood aan ons durven toevertrouwen nodigen we jullie van harte uit voor een zgn. intake(plus) - op afspraak - bij Stichting (V)écht ervoor! in Zutphen."
              title="Intake"
            >
              1
            </AppUSPCard>
          </Grid>
          <Grid item md={12} lg={6} className={classes.content}>
            <AppUSPCard
              background="op_stoeltjes_voor_kachel.jpg"
              desc="De intake(plus) is voor nadere kennismaking en om te luisteren naar jullie verhaal. N.a.v. deze persoonlijke eerste ontmoeting doen we aanbevelingen voor een mogelijk vervolg."
              title="Intakeplus"
            >
              2
            </AppUSPCard>
          </Grid>
          <Grid item md={12} lg={6} className={classes.content}>
            <AppUSPCard
              background="op_bank.jpg"
              desc="De intake(plus) heeft meestal een vervolg in de vorm van counseling. Vaak blijkt er meer nodig te zijn voor een blijvend resultaat: herhaling, verdieping, groeiend vertrouwen, bewustere keuzes, beter inzicht."
              title="Vervolg counseling"
            >
              3
            </AppUSPCard>
          </Grid>
          <Grid item sm={12} justify="center" align="center">
            <AppButton
              styles={{
                "min-width": "250px",
                "font-size": "1.1rem",
                height: "60px",
              }}
              href="/counseling"
            >
              Meer informatie
            </AppButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
