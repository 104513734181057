import React from "react";
import { makeStyles, Grid, createStyles } from "@material-ui/core";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      margin: "0",
      width: "100%",
      padding: "40px 80px",
      [theme.breakpoints.down("sm")]: {
        padding: "20px",
      },
    },
    content: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
    },
  })
);

export default function SimpleBreadcrumbs({ backgroundColor, current }) {
  const classes = useStyles();

  return (
    <Grid
      container
      className={classes.container}
      justify="space-around"
      style={{ backgroundColor }}
    >
      <Grid item xs={12} className={classes.content}>
        {(({ location }) => {
          const pathnames = location.pathname.split("/").filter((x) => x);

          return (
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={<NavigateNextIcon fontSize="small" />}
            >
              <Link color="inherit" href="/">
                Home
              </Link>
              {pathnames.map((value, index) => {
                const last = index === pathnames.length - 1;
                const to = `/${pathnames.slice(0, index + 1).join("/")}`;

                return last ? (
                  <Typography color="textPrimary" key={to}>
                    {current ? current : to.split("/")?.[1]}
                  </Typography>
                ) : (
                  <Link color="inherit" href={to} key={to}>
                    {to.split("/")?.[1]}
                  </Link>
                );
              })}
            </Breadcrumbs>
          );
        })(window)}
      </Grid>
    </Grid>
  );
}
