import React from "react";
import AppHeader from "../../../../components/app-hero-header";

export default function () {
  return (
    <AppHeader
      subTitle="Stichting (V)écht ervoor!, gevestigd aan De Varentuin 11,7207ML Zutphen, is verantwoordelijk voor de verwerking van persoonsgegevens zoals weergegeven in deze privacyverklaring."
      title="Privacyverklaring"
      background="achterkant.jpg"
    />
  );
}
