import React from "react";
import { Helmet } from "react-helmet";
import { Divider } from "@material-ui/core";
import AppHeader from "./components/header";
import ReneTextImage from "./components/rene-text";
import LucyTextImage from "./components/lucy-text";
import AppTextImage from "./components/about-us-text";
import TopCallToAction from "../../framework/top-call-to-action";
import BreadCrumbs from "../../framework/breadcrumb";

export default function AppAbout() {
  return (
    <React.Fragment>
      <Helmet>
        <title>Over Stichting (V)écht ervoor!</title>
        <meta
          name="description"
          content="Stichting (V)écht ervoor! is opgericht door Lucy (1954) &amp; René (1950) Grifhorst, getrouwd in 1975. Samen hebben zij 4 kinderen gekregen en zijn opa en oma van negen kleinkinderen."
        />
        <meta
          property="og:description"
          content="Stichting (V)écht ervoor! is opgericht door Lucy (1954) &amp; René (1950) Grifhorst, getrouwd in 1975. Samen hebben zij 4 kinderen gekregen en zijn opa en oma van negen kleinkinderen."
        />
        <meta property="og:title" content="Over Stichting (V)écht ervoor!" />
        <meta property="og:url" content={"https://vechtervoor.com/over-ons"} />
      </Helmet>
      <Divider />
      <AppHeader />
      <Divider />
      <BreadCrumbs backgroundColor="#FFF1E3" />
      <Divider />
      <AppTextImage />
      <Divider />
      <ReneTextImage />
      <Divider />
      <LucyTextImage />
      <Divider />
      <TopCallToAction backgroundColor="#ffe5ca" />
      <Divider />
    </React.Fragment>
  );
}
