import React from "react";
import { Helmet } from "react-helmet";
import { Divider } from "@material-ui/core";
import AppHeader from "./components/header";
import AppTextTop from "./components/text-top";
import BreadCrumbs from "../../framework/breadcrumb";

export default function AppReview() {
  return (
    <React.Fragment>
      <Helmet>
        <title>
          Huwelijksinvestering | Stiching Vecht Ervoor! | Privacyverklaring
        </title>
        <meta
          name="description"
          content="Stichting (V)écht ervoor!, gevestigd aan De Varentuin 11,7207ML Zutphen, is verantwoordelijk voor de verwerking van persoonsgegevens zoals weergegeven in onze privacyverklaring."
        />
        <meta
          property="og:description"
          content="Stichting (V)écht ervoor!, gevestigd aan De Varentuin 11,7207ML Zutphen, is verantwoordelijk voor de verwerking van persoonsgegevens zoals weergegeven in onze privacyverklaring."
        />
        <meta
          property="og:title"
          content="Huwelijksinvestering | Stiching Vecht Ervoor! | Privacyverklaring"
        />
        <meta
          property="og:url"
          content={"https://vechtervoor.com/privacyverklaring"}
        />
      </Helmet>
      <Divider />
      <AppHeader />
      <Divider />
      <BreadCrumbs
        backgroundColor="rgb(255 229 202)"
        current="privacyverklaring"
      />
      <Divider />
      <AppTextTop />
      <Divider />
    </React.Fragment>
  );
}
