import React from "react";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles, Snackbar } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function AppSnackBar({ message, severity, handler, open }) {
  // @ts-ignore
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Snackbar open={open} autoHideDuration={6000} onClose={handler}>
        <Alert onClose={handler} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}
