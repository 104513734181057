import React, { useContext } from "react";
import { Grid, createStyles, makeStyles, Typography } from "@material-ui/core";
import AppCarousel from "../../components/app-carousel";
import AppButton from "../../components/app-button";
import { Context } from '../../store/store'

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      margin: "0",
      width: "100%",
      padding: "80px 40px",
      background: "#ffe5ca",
    },
    content: {
      display: "flex",
      justifyContent: "center",
    },
    title: {
      fontFamily: "'Raleway', sans-serif;",
      marginBottom: "50px",
    },
    subTitle: {
      marginBottom: "35px",
    },
  })
);

export default function ReviewComponent({ backgroundColor, title, desc }) {
  const classes = useStyles();
  const [ state ] = useContext(Context);

  return (
    <Grid container className={classes.container} justify="space-evenly" style={{ backgroundColor: backgroundColor || '#ffe5ca' }}>
      <Grid item sm={12} justify="center" align="center">
        <Typography
          variant="h2"
          component="h2"
          gutterBottom
          className={classes.title}
        >
          {title || 'Ervaringen van stellen'}
        </Typography>
        <Typography
          variant="subtitle1"
          component="h3"
          className={classes.subTitle}
        >
          {desc || `Als (christelijke) huwelijken gezonder, dus sterker worden, dan worden
          hun gezinnen (en kerkelijke gemeenten) dat ook!`}<br />
        </Typography>
      </Grid>
      <Grid item sm={12} md={8} xl={8} justify="center" align="center">
        <AppCarousel items={state.reviews} />
      </Grid>
      <Grid item sm={12} md={8} xl={8} justify="center" align="center">
        <AppButton styles={{ marginTop: "35px" }} href={"/getuigenissen"}>
          Bekijk alle getuigenissen
        </AppButton>
      </Grid>
    </Grid>
  );
}
