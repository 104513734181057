import React, { useContext } from "react";
import { Grid, createStyles, makeStyles } from "@material-ui/core";
import Reviews from "../../../../components/app-reviews";
import { Context } from '../../../../store/store'

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      margin: "0",
      width: "100%",
      padding: "40px",
      background: "#FFF1E3",
      [theme.breakpoints.down("sm")]: {
        padding: "40px 0",
      },
    },
  })
);

export default function AppQuery() {
  const classes = useStyles();
  const [state] = useContext(Context);

  return (
    <Grid
      container
      spacing={10}
      className={classes.container}
      justify="space-between"
    >
      <Reviews reviews={state.reviews} />
    </Grid>
  );
}
