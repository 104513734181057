import React from "react";
import { Grid, createStyles, makeStyles, Typography } from "@material-ui/core";
import ReactMarkdown from "react-markdown";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      margin: "0",
      width: "100%",
      padding: "40px",
      display: "flex",
      alignContent: "center",
      alignItems: "center",
      background: "#FFF1E3",
      [theme.breakpoints.down("sm")]: {
        padding: "40px 0",
      },
    },
    content: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      padding: "40px",
    },
    image: {
      width: "100%",
      borderRadius: "0.25rem",
      boxShadow: "0 0 2.5rem 0.3125rem rgba(0,0,0,.3)",
    },
    text: {
      fontFamily: "'Raleway', sans-serif;",
      fontWeight: 300,
      lineHeight: 1.8,
      textAlign: "justify",
      fontSize: "1.1rem",
    },
    title: {
      display: "flex",
      textTransform: "uppercase",
      fontFamily: "'Raleway', sans-serif;",
      fontWeight: 700,
      fontSize: "2rem",
      position: "relative",
      marginBottom: "2rem",
      lineHeight: 2,
      "&::after": {
        position: "absolute",
        content: '""',
        bottom: 0,
        width: "80px",
        height: "3px",
        background: "#666666",
        transform: "translateX(0%)",
        [theme.breakpoints.down("sm")]: {
          display: 'none'
        },
      },
    },
  })
);

export default function AppTextImage({
  text,
  title,
  reverse = false,
  image,
  iframe,
  backgroundColor,
  children,
}) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid
        container
        justify="space-around"
        direction={reverse ? "row-reverse" : "row"}
        className={classes.container}
        spacing={10}
        style={backgroundColor && { backgroundColor: backgroundColor }}
      >
        <Grid item sm={12} lg={7} className={classes.content}>
          {title ? (
            <Typography variant="h2" gutterBottom className={classes.title}>
              {title}
            </Typography>
          ) : (
            ""
          )}
          <ReactMarkdown source={text} className={classes.text} />
          {children}
        </Grid>
        {image && (
          <Grid item sm={12} lg={5} className={classes.content}>
            <img src={image} alt={title} className={classes.image} />
          </Grid>
        )}
        {iframe && (
          <Grid item sm={12} lg={5} className={classes.content}>
            <iframe
              width="100%"
              height="350"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=nl&amp;q=+(stichting%20(V)%C3%A9cht%20ervoor!)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            ></iframe>
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
}
