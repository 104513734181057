import React from "react";
import AppButton from '../../../../components/app-button'
import AppTextImage from "../../../../components/app-text-image";

export default function TextArea() {
  return (
    <AppTextImage
      title="Over ons"
      reverse
      text={`Wij zijn Lucy (1954) & René (1950) Grifhorst, getrouwd in 1975. We hebben 4 kinderen gekregen en zijn opa en oma van negen kleinkinderen. We zijn heel tevreden met elkaar... Maar als christenen lopen we ook tegen onze tekortkomingen aan. Volmaakt in Christus, maar niet altijd volmaakt in ons huwelijk. Dat kan ook niet altijd, maar we “vechten” er voor om ons huwelijk, in Zijn kracht, zo goed mogelijk te maken en te houden.`}
      image={require("../../../../assets/aan_tafel.jpg")}
    >
      <AppButton styles={{maxWidth: '200px', margin: 0}} href="/over-ons">Lees verder</AppButton>
    </AppTextImage>
  );
}
