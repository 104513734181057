import React from "react";
import { Helmet } from "react-helmet";
import { Divider } from "@material-ui/core";
import AppHeader from "./components/header";
import AppText from "./components/text";
import TopCallToAction from "../../framework/top-call-to-action";
import BreadCrumbs from "../../framework/breadcrumb";

export default function AppCounseling() {
  return (
    <React.Fragment>
      <Helmet>
        <title>ANBI-status van (V)écht ervoor!</title>
        <meta
          name="description"
          content="Stichting (V)écht ervoor! is een stichting met ANBI-status zonder winstoogmerk. U kunt ons steunen door een bedrag over te maken."
        />
        <meta
          property="og:description"
          content="Stichting (V)écht ervoor! is een stichting met ANBI-status zonder winstoogmerk. U kunt ons steunen door een bedrag over te maken."
        />
        <meta property="og:title" content="ANBI-status van (V)écht ervoor!" />
        <meta
          property="og:url"
          content={"https://vechtervoor.com/anbi-status"}
        />
      </Helmet>
      <Divider />
      <AppHeader />
      <Divider />
      <BreadCrumbs backgroundColor="rgb(255, 229, 202)" />
      <Divider />
      <AppText />
      <Divider />
      <TopCallToAction backgroundColor="#ffe5ca" />
      <Divider />
    </React.Fragment>
  );
}
