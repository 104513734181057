import React from "react";
import AppTextImage from "../../../../components/app-text-image";

export default function TextArea() {
  return (
    <AppTextImage
      backgroundColor="rgb(255 229 202)"
      title="Achtergrond"
      image={require("../../../../assets/rene_grifthorst.jpg")}
      text={`Na jaren in het bedrijfsleven werkzaam te zijn geweest werden we in 1991 fulltime Discovery-werkers vanuit de internationale evangelisatie- en toerustingsbeweging Agapè in Doorn. In de loop der tijd veranderde ons werk van specifiek jongerenwerk naar een bredere doelgroep. Dat resulteerde o.a. in het Agapè-stadswerk in Zutphen. Echter, vooral de pastorale zorg speciaal voor echtparen - en jonge stellen, die huwelijksvoorbereiding van ons krijgen - bepaalde ons leven meer en meer. We hebben, om die pastorale zorg (ook didactisch) te onderbouwen, o.a. een 4-jarige opleiding bij Koinonia gevolgd: "de school voor pastorale werkers en christelijke hulpverleners". Sinds januari 2008 zijn wij fulltime, als huwelijkscounselors, verbonden aan stichting (V)écht ervoor!`}
    />
  );
}
