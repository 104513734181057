import "dotenv/config";
import React from "react";
import ReactDOM from "react-dom";
import AppMain from "./app-main";
import Store from "./store/store";
import "./index.css";

const rootElement = document.getElementById("vecht-ervoor");

ReactDOM.render(
  <Store>
    <AppMain />
  </Store>,
  rootElement
);

window.dataLayer.push({
  event: "pageview",
});
