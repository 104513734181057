import React, { useContext } from "react";
import { Grid, makeStyles, createStyles, Divider } from "@material-ui/core";
import ReactMarkdown from "react-markdown";
import { Helmet } from "react-helmet";
import AppHeader from "./components/header";
import TopCallToAction from "../../framework/top-call-to-action";
import BreadCrumbs from "../../framework/breadcrumb";
import { Context } from '../../store/store'

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      margin: "0",
      width: "100%",
      background: "#FFF1E3",
    },
    content: {
      fontFamily: "'Raleway', sans-serif;",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      fontSize: "1.1rem",
      textAlign: "justify",
      fontWeight: 300,
      lineHeight: 1.8,
      padding: "40px",
    },
    title: {
      fontFamily: "'Raleway', sans-serif;",
      marginBottom: "50px",
    },
    card: {
      borderTopRightRadius: "45px",
    },
  })
);

const Review = ({ slug }) => {
  const classes = useStyles();
  const [ state ] = useContext(Context);

  const review = state.reviews.find((item) => item.slug === slug);

  if (!review) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>{review.title}</title>
        <meta name="description" content={review.subtitle} />
        <meta property="og:image" content={review.image.url} />
        <meta property="og:description" content={`${review.title}`} />
        <meta property="og:title" content={review.title} />
        <meta
          property="og:url"
          content={`https://vechtervoor.nl/blog/review/${slug}`}
        />
        <meta property="og:type" content="article" />
        <meta property="og:site_name" content="vechtervoor.nl" />
      </Helmet>
      <AppHeader title={review.title} />
      <BreadCrumbs backgroundColor="#FFF1E3" current={review.slug} />
      <Divider />
      <Grid container className={classes.container} justify="space-evenly">
        <Grid item xs={12} sm={10} md={8} justify="center" align="center">
          <ReactMarkdown source={review.content} className={classes.content} />
        </Grid>
        <TopCallToAction backgroundColor="#ffe5ca" />
      </Grid>
    </>
  );
};

export default Review;
