import React from "react";
import AppTextImage from "../../../../../components/app-text-image";

export default function TextArea() {
  return (
    <AppTextImage
      backgroundColor="#FFF1E3"
      title="Kosten voor relatietherapie"
      text={`Afhankelijk van de vorm waarin de hulp wordt aangeboden, kunnen de kosten hoger of lager uitvallen.\n\n### On-line therapie\n\nNa telefonisch overleg en/of email kunnen we waar gewenst - beveiligde - on line hulp bieden, via Whatsapp-videobellen, of anderszins. De kosten van het intake zijn **gratis** het vervolggesprek kost **minimaal 20 euro per uur**.\n\n### Pastoraal intake gesprek\n\nAls jullie je huwelijksnood aan ons durven toevertrouwen nodigen we jullie van harte uit voor een zgn. intake(plus) - op afspraak - bij ons thuis in Zutphen. Je kunt ons daarvoor mailen of bellen (zie Contact). De intake(plus) is voor nadere kennismaking en om te luisteren naar jullie verhaal. N.a.v. deze persoonlijke eerste ontmoeting doen we aanbevelingen voor een mogelijk vervolg. Vooral voor degenen die van ver moeten komen is de intakeplus een logische start van een eventueel counselingtraject. De kosten hiervan zijn **tussen de 70 en 100 euro.**\n\n### Vervolg relatietherapie\n\nDe intake(plus) heeft meestal een vervolg in de vorm van counseling. Vaak blijkt er meer nodig te zijn voor een blijvend resultaat: herhaling, verdieping, groeiend vertrouwen, bewustere keuzes, beter inzicht. Het bespreken en doorleven van bepaalde thematiek, het samen uitwerken van opdrachten, het lezen van bepaalde boeken en - waar nodig - individuele gesprekken, zijn mogelijke onderdelen. Vervolg counseling vindt plaats op een ochtend/middag of tijdens een eendaagse, met een frequentie van plm. 3 à 4 weken. De kosten zijn **tussen de 45 en 160 euro.**`}
      image={require("../../../../../assets/aan_tafel.jpg")}
    >
    </AppTextImage>
  );
}
