import React from "react";
import { Grid, createStyles, makeStyles, Typography } from "@material-ui/core";
import classnames from 'classnames';
import Form from "../../../../../framework/form";

const useStyles = makeStyles((theme) =>
  createStyles({
    colorWhite: {
      color: "#fff",
    },
    container: {
      minHeight: "100vh",
      margin: "-65px 0 0",
      width: "100%",
      backgroundImage: `url(${require(`../../../../../assets/achterkant.jpg`)})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundAttachment: "fixed",
      backgroundPosition: "center",
      [theme.breakpoints.down("sm")]: {
        minHeight: "50vh",
        backgroundAttachment: "inherit",
      },
    },
    content: {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      padding: "80px 80px 80px 130px !important",
      [theme.breakpoints.down("md")]: {
        padding: "40px !important",
      },
    },
    title: {
      color: "#FFF",
      fontFamily: "'Raleway', sans-serif;",
      fontWeight: 700,
      textTransform: "uppercase",
      [theme.breakpoints.down("md")]: {
        fontSize: '58px',
      },
      [theme.breakpoints.down("sm")]: {
        textAlign: 'center',
      },
    },
    subTitle: {
      color: "#FFF",
      fontFamily: "'Raleway', sans-serif;",
      fontSize: "1rem",
      fontSize: "22px",
      [theme.breakpoints.down("sm")]: {
        display: 'none'
      },
    },
    formText: {
      width: '100%'
    },
    displayNoneSmall: {
      [theme.breakpoints.down("md")]: {
        display: 'none'
      },
    }
  })
);

export default function Header() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid
        container
        spacing={10}
        className={classes.container}
        justify="space-around"
      >
        <Grid item xs={12} sm={12} md={6} className={classes.content}>
          <Typography
            variant="h1"
            component="h1"
            gutterBottom
            className={classes.title}
          >
            Christelijk huwelijks pastoraat
          </Typography>
          <Typography
            variant="subtitle1"
            component="p"
            gutterBottom
            className={classes.subTitle}
          >
            Ieder huwelijk is het waard om voor te vechten. Stichting (V)écht
            ervoor! biedt Christelijk huwelijks pasoraat aan echtparen die in een bepaalde
            relationele nood zitten geestelijke en praktische handvatten aan
            te reiken. Dat doen we vanuit een christelijke invalshoek: de
            uitgangspunten van de Here God en Zijn woord.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} className={classnames(classes.content, classes.displayNoneSmall)}>
          <Form style={{
            backgroundColor: '#FFF1E3',
            padding: '40px',
            boxShadow:
      "-1px 3px 20px 20px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
            borderRadius: '15px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            maxWidth: '500px'
          }}>
          <Typography variant="h6" component="h3" gutterBottom align="center" className={classes.formText}>
            Ontvang hulp
          </Typography>
          <Typography variant="p" component="h4" gutterBottom align="center" className={classes.formText} style={{marginBottom: '30px'}}>
            Verkeert jullie huwelijk in moeilijkheden? <br />
            Plan een pastoraal gesprek.
          </Typography>
          </Form>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
