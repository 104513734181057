import React from "react";
import { Helmet } from "react-helmet";
import { Divider } from "@material-ui/core";
import AppHeader from "./components/header";
import VervolgCounselingText from "./components/vervolg-counseling-text";
import DraagkrachtText from "./components/draagkracht-text";
import CounselingText from "./components/counseling-text";
import TopCallToAction from "../../framework/top-call-to-action";
import BreadCrumbs from "../../framework/breadcrumb";

export default function AppCounseling() {
  return (
    <React.Fragment>
      <Helmet>
        <title>Intake(plus) en vervolgcounseling bij (V)écht ervoor!</title>
        <meta
          name="description"
          content="Als jullie je huwelijksnood aan ons durven toevertrouwen nodigen we jullie van harte uit voor een zgn. intake(plus) - op afspraak - bij ons Stichting (V)écht ervoor! in Zutphen."
        />
        <meta
          property="og:description"
          content="Als jullie je huwelijksnood aan ons durven toevertrouwen nodigen we jullie van harte uit voor een zgn. intake(plus) - op afspraak - bij ons Stichting (V)écht ervoor! in Zutphen."
        />
        <meta
          property="og:title"
          content="Intake(plus) en vervolgcounseling bij (V)écht ervoor!"
        />
        <meta
          property="og:url"
          content={"https://vechtervoor.com/counseling"}
        />
      </Helmet>
      <Divider />
      <AppHeader />
      <Divider />
      <BreadCrumbs backgroundColor="rgb(255 229 202)" />
      <Divider />
      <CounselingText />
      <Divider />
      <VervolgCounselingText />
      <Divider />
      <DraagkrachtText />
      <Divider />
      <TopCallToAction backgroundColor="#ffe5ca" />
      <Divider />
    </React.Fragment>
  );
}
