import React from "react";
import AppHeader from "../../../../components/app-hero-header";

export default function TextArea() {
  return (
    <AppHeader
      subTitle="Als stichting hebben wij geen winstoogmerk."
      title="ANBI-status"
      background="aan_tafel.jpg"
    />
  );
}
