import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const StyledTableCell = withStyles((theme) => ({
  head: {
    fontSize: 16,
    fontWeight: 700,
  },
  body: {
    fontSize: 16,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#FFF1E3",
    },
    "&:nth-of-type(even)": {
      backgroundColor: "#ffe5ca",
    },
  },
}))(TableRow);

function createData(name, value, color, fontWeight) {
  return { name, value, color, fontWeight };
}

const rows = [
  createData("", "", ""),
  createData("Bestuurssamenstelling", "", "", 700),
  createData("Voorzitter", "Erik Bardelmeijer"),
  createData("Secretariaat", "Hanneke Knol"),
  createData("Penningmeester", "Henk Knol"),
  createData("Algemene zaken", "Mirjam Bardelmeijer"),
  createData("", "", ""),
  createData("Fiscale informatie", "", "", 700),
  createData(
    "Beloningsbeleid",
    "bestuur en werkers zijn onbezoldigd verbonden aan de stichting."
  ),
  createData("Fiscaal nummer", 816854348),
  createData("KvK-nummer", "08143603"),
];

const useStyles = makeStyles({
  table: {
    widht: "100%",
    margin: "0 auto",
  },
  thead: {
    background: "#E8A279",
  },
  colorWhite: {
    color: "#FFF!important",
  },
});

export default function CustomizedTables() {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead className={classes.thead}>
          <TableRow>
            <StyledTableCell className={classes.colorWhite}>
              Naam stichting
            </StyledTableCell>
            <StyledTableCell align="right" className={classes.colorWhite}>
              Stichting (V)écht ervoor!
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.name} style={{ background: row.color }}>
              <StyledTableCell
                component="th"
                scope="row"
                style={{ fontWeight: row.fontWeight }}
              >
                {row.name}
              </StyledTableCell>
              <StyledTableCell align="right">{row.value}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
