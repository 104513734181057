import React from "react";
import { Helmet } from "react-helmet";
import { Divider } from "@material-ui/core";
import AppHeader from "./components/header";
import AppText from "./components/text";
import TopCallToAction from "../../framework/top-call-to-action";
import BreadCrumbs from "../../framework/breadcrumb";

export default function AppContact() {
  return (
    <React.Fragment>
      <Helmet>
        <title>Neem contact op met Stichting (V)écht ervoor!</title>
        <meta
          name="description"
          content="Verkeert jullie huwelijk in moeilijkheden? Neem dan contact op met Stichting (V)écht ervoor! en plan een ontmoeting!"
        />
        <meta
          property="og:description"
          content="Verkeert jullie huwelijk in moeilijkheden? Neem dan contact op met Stichting (V)écht ervoor! en plan een ontmoeting!"
        />
        <meta
          property="og:title"
          content="Neem contact op met Stichting (V)écht ervoor!"
        />
        <meta property="og:url" content={"https://vechtervoor.com/contact"} />
      </Helmet>
      <Divider />
      <AppHeader />
      <Divider />
      <BreadCrumbs backgroundColor="rgb(255, 229, 202)" />
      <Divider />
      <AppText />
      <Divider />
      <TopCallToAction backgroundColor="#ffe5ca" />
      <Divider />
    </React.Fragment>
  );
}
