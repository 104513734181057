import React from "react";
import AppHeader from "../../../../components/app-hero-header";

export default function TextArea() {
  return (
    <AppHeader
      subTitle="Plan een ontmoeting!"
      title="Contact"
      background="op_bank.jpg"
    />
  );
}
