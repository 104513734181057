import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      color: "#FFF",
      fontSize: "1.1rem",
      height: "60px",
      margin: theme.spacing(1),
      minWidth: "175px",
      backgroundColor: "#f2713a",
      "&:hover": {
        backgroundColor: "rgb(169, 79, 40)",
      },
    },
  })
);

export default function CustomizedButtons({
  props,
  children,
  styles,
  href,
  handler = () => {
    window.dataLayer.push({
      event: 'button.click',
    })
  },
}) {
  const classes = useStyles();

  return (
    <Button
      onClick={handler}
      href={href}
      variant="contained"
      // color="primary"
      style={styles || {}}
      className={classes.button}
      {...props}
    >
      {children}
    </Button>
  );
}
