import React from "react";
import { Divider } from "@material-ui/core";
import { Helmet } from "react-helmet";

import AppTextArea from "./components/text-area";
import AppTextKosten from "./components/text-area-kosten";
import AppHeader from "./components/header";
import AppFormBottom from "./components/form-bottom";
import TopCallToAction from "../../../framework/top-call-to-action";
import withRoot from "../../../theme/withRoot";

function AppHome() {
  return (
    <React.Fragment>
      <Helmet>
        <title>Relatietherapie voor Christelijke echtparen</title>
        <meta
          name="description"
          content="Relatie therapie voor christelijke echtparen - die in een bepaalde relationele nood zitten - vanuit een christelijke invalshoek."
        />
        <meta
          property="og:description"
          content="Relatie therapie voor christelijke echtparen - die in een bepaalde relationele nood zitten - vanuit een christelijke invalshoek."
        />
        <meta
          property="og:title"
          content="Relatietherapie voor Christelijke echtparen"
        />
        <meta property="og:url" content={"https://vechtervoor.com/relatie-therapie"} />
      </Helmet>
      <Divider />
      <AppHeader />
      <Divider />
      <AppTextArea />
      <Divider />
      <TopCallToAction title="Relatie therapie nodig? Neem contact op!" backgroundColor="#ffe5ca" />
      <Divider />
      <AppTextKosten />
      <Divider />
      <AppFormBottom />
    </React.Fragment>
  );
}

export default withRoot(AppHome);
